<template>
  <div>
    <div class="create-team-wrap">
      <div class="create-team-box">
        <div class="create-inp-item vertical-center">
          <p class="inp-label"><span class="em">*</span>小队名称</p>
          <input type="text" v-model="name" placeholder="请输入小队名称" maxlength="15" class="inp-value">
        </div>
        <div class="create-inp-item vertical-center">
          <p class="inp-label">小队长</p>
          <input type="text" @input="teamSearch" v-model="keyword" placeholder="请输入小队长信息" maxlength="20" class="inp-value">
        </div>
      </div>
      <!--  搜索结果-->
      <div class="search-box" v-if="searchData.length">
        <div class="search-list vertical-center" v-for="(item,index) in searchData" :key="index" @click="chooseTeam(item)">
          <img :src="item.avatar ? item.avatar :'https://static.helixlife.cn/edu/home/images/avatar.png'" class="avatar-img" alt="">
          <p class="name white-space">{{item.nickname ? item.nickname : '酸谈社群'}}</p>
          <p class="mobile">{{item.mobile ? item.mobile: ''}}</p>
        </div>
      </div>

      <div class="create-btn" v-if="name.trim().length > 0" @click="teamSubmit">确认</div>
      <div class="create-btn edit-btn-default" v-else>确认</div>

    </div>
    <!-- 加载提示   -->
    <van-overlay :show="login_loading_show" z-index="9999">
      <div class="loading-wrap">
        <van-loading type="spinner"/>
      </div>
    </van-overlay>
  </div>

</template>

<script>
import _ from "lodash";

export default {
  name: "Create",
  data() {
    return {
      name: '', // 小队名称
      user_id: '', // 选择用户id
      searchData: [], // 搜索结果
      keyword: '',
      login_loading_show: false
    }
  },
  created() {
    if (this.userInfo && this.userInfo.is_team_master != 1) {
      this.$router.replace('/user')
    }
  },
  methods: {
    /*创建小队提交*/
    teamSubmit: _.debounce(function () {
      if (this.name.trim() == '') {
        this.$toast('请输入小队名称')
        return false
      }
      if(this.keyword.trim() != '' && this.keyword.length != 11) {
        this.$toast('请选择正确的小队长')
        return false
      }
      if(this.keyword.trim().length == 11 && this.user_id == ''){
        this.$toast('请选择正确的小队长')
        return false
      }
      if(this.keyword.trim() == '') {
        this.user_id= ''
      }
      this.login_loading_show= true
      this.teamData()
    }, 500),
    // 提交数据
    teamData() {
      let url = this.$api.TeamGroups
      let data = {
        name: this.name
      }
      if(this.user_id != '') {
        data.user_id= this.user_id
      }
      this.$http.post(url, data, true).then(res => {
        if (res.data.success) {
          this.defaultFun()
          setTimeout(()=>{
            this.login_loading_show= false
            this.$toast('创建成功')
          },200)
          setTimeout(()=>{
            this.$router.replace('/management-team')
          },1200)
        } else {
          setTimeout(()=>{
            this.login_loading_show= false
          },200)
          this.$toast(res.data.message)
        }
      }).catch(error => {
        setTimeout(()=>{
          this.login_loading_show= false
        },200)

        if (error.response.status == 422) {
          this.$toast(error.response.data.message);
        }
      })
    },
    // 获取搜索匹配
    teamSearch() {
      let url= this.$api.TeamSearch + '?keyword=' + this.keyword
      this.$http.get(url, true).then(res => {
        if(res.data.success) {
          this.searchData= res.data.data
        }else {
          this.searchData= []
        }
      })
    },
    // 点击选择小队
    chooseTeam(item) {
      this.user_id= item.id
      this.keyword= item.mobile
      this.searchData= []
    },
    // 默认值回复
    defaultFun() {
      this.name= ''
      this.user_id= ''
      this.keyword= ''
      this.searchData= []
    }
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    }
  },
  beforeCreate() {
    document.querySelector('body').setAttribute('style', 'background:#F7F7F7')
  },
  beforeUnmount() {
    document.querySelector('body').setAttribute('style', 'background:#FFFFFF')
  }
}
</script>

<style lang="scss" scoped>
@import "~assets/scss/user/team-management/create.scss";
.loading-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.edit-btn-default{
  background: #BFBFBF!important;
}
</style>